@use '../atoms/lists';

.a-miniCartButton {
  // TODO: fix default button selectors !
  position: relative !important;
}
.a-miniCartButton_count {
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  width: 1.5em;
  height: 1.5em;
  background: var(--color-primary);
  color: var(--text-on-primary);
  border-radius: 50%;
  &:empty {
    display: none;
  }
  @media (--viewport-lg-up) {
    font-size: inherit;
  }
}

.o-miniCartDialog {}

.o-miniCart_total {
  margin-top: 1rem;
  font-size: 19px;
}
.o-miniCart_buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1ch;
  margin-top: 1rem;
}
