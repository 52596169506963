
.swiper {
  --swiper-theme-color: var(--color-primary);
  --swiper-navigation-color: var(--color-primary-dark);
  --swiper-preloader-color: var(--color-primay);
}

.swiper {
  width: 100%;
}
.swiper-lazy-preloader {
  pointer-events: none;
}
