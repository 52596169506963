
.a-badge {
  --badge-bg: var(--dark-background-color);
  display: inline-block;
  padding: 6px 8px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: var(--badge-bg);
  color: var(--text-on-dark-background);
  text-transform: uppercase;
}

.a-badge.-danger {
  --badge-bg: var(--color-danger);
  color: var(--text-on-danger);
}

.a-badge.-flag-left {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 15px 50%);
  padding-left: 20px;
}
.a-badge.-flag-right {
  clip-path: polygon(0% 0%, 100% 0%, calc(100% - 15px) 50%, 100% 100%, 0% 100%);
  padding-right: 20px;
}
