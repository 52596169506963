
.p-customerLogin {
  display: grid;
  grid-template-columns: auto;
  gap: 3rem;
  &.-isRegistrationEnabled {
    @media (--viewport-md-up) {
      & {
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
      }
    }
    @media (--viewport-xl-up) {
      & {
        gap: 3rem;
      }
    }
  }

  & form {
    display: flex;
    flex-direction: column;
    & h2 {
      margin-top: 0;
    }
    & .required {
      color: var(--color-danger);
      font-weight: bolder;
    }
  }

  & .m-formRow {
    display: flex;
    flex-direction: column;
    &.-horizontal {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1ch;
      flex-wrap: wrap;
    }
    & label:not(.a-formCheck) {
      margin-bottom: .5rem;
    }
  }
}

.woocommerce-privacy-policy-text {
  font-size: .85em;
  line-height: 1.5em;
}
