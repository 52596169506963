#site-header {
  display: none;
  @media (--viewport-lg-up) {
    & {
      display: block;
    }
  }
}

#header-sticky .row {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 15px;
  margin: 0;
}
#header-sticky .row > * {
  max-width: none !important;
  padding: 0;
}

