
.o-faq_question {
  color: var(--color-primary);
}

.o-faq_answer {
  margin-top: 1rem;
  @media (--viewport-md-up) {
    padding-left: 1.5rem;
  }
}
