
// We don't use the mobile-first approach here since it is simpler
.o-cart_items {
  width: 100%;
  display: grid;
  thead, tbody, tr {
    display: contents;
  }
  .o-cartItem_image img {
    width: 100%;
    height: auto;
  }
  thead {
    font-weight: bold;
    th:not(:first-child) {
      text-align: center;
    }
  }
  td:not(:first-child) {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (--viewport-md-up) {
    grid-template-columns: 1fr repeat(4, minmax(100px, max-content));
    th, td {
      border-bottom: 1px solid var(--grey-300);
      padding: 1rem 0;
    }
    .o-cartItem_designation {
      display: grid;
      grid-template-columns: 100px 1fr;
      grid-column-gap: 1rem;
    }
  }

  @media (--viewport-md-down) {
    grid-template-columns: auto;
    grid-row-gap: 1.5rem;
    thead {
      display: none;
    }
    tr {
      display: grid;
      grid-template:
        "img desc desc desc"
        "img qtty total actions"
        / 100px auto auto 1fr
      ;
      grid-column-gap: 1rem;
      align-items: center;
    }
    td {
      border: none;
    }
    .o-cartItem_designation {
      display: contents;
    }
    .o-cartItem_image {
      align-self: start;
      grid-area: img;
    }
    .o-cartItem_content {
      align-self: start;
      grid-area: desc;
    }
    td.o-cartItem_price {
      display: none;
    }
    .o-cartItem_quantity {
      grid-area: qtty;
    }
    .o-cartItem_subtotal {
      grid-area: total;
    }
    .o-cartItem_actions {
      grid-area: actions;
      justify-content: flex-end;
    }
  }
}

.o-cart_footer {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 2ch;
}

.o-cartCollaterals {
  .shop_table {
    th {
      font-weight: bold;
    }
    tr:not(:first-child) {
      border-top: 1px solid var(--grey-300);
    }
    th, td {
      vertical-align: top;
      padding: .5rem;
    }
  }
}
