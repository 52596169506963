.a-container {
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;

  @media (--viewport-sm-up) {
    & {
      max-width: 540px;
    }
  }
  @media (--viewport-md-up) {
    & {
      max-width: 720px;
    }
  }
  @media (--viewport-lg-up) {
    & {
      max-width: 960px;
    }
  }
  @media (--viewport-xl-up) {
    & {
      max-width: 1300px;
    }
  }
}
