
// The size guide button
.o-wcSingleProduct_addToCart {
  .variation-attribute[data-attribute="pa_taille"] {
    > .value {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }
}

// The size guide dialog
.o-sizeGuide {
  &_conversionTable {
    table {
      width: 100%;
    }
    th, td {
      text-align: center;
    }
    tbody > tr:nth-child(odd) {
      background-color: var(--grey-300);
    }
  }
  &_handMeasurement {
    figure {
      display: grid;
      gap: 1rem;
      grid-template:
        "caption"
        "image"
      ;
      align-items: center;
      figcaption {
        grid-area: caption;
      }
      svg {
        grid-area: image;
        max-width: 100%;
        height: auto;
        --hand-color: var(--grey-300);
      }
      @media (--viewport-md-up) {
        grid-template:
          "image caption" auto / 1fr 2fr
        ;
      }
    }
  }
}
