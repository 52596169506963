#navbar {
  position: sticky;
  top: 0;
  z-index: var(--z-index-appbar);
  box-shadow: var(--elevation-appbar);
  transition: box-shadow .3s ease-in;
  &.-isScrolled {
    box-shadow: var(--elevation-appbar-scrolled);
  }
}

@media (min-width: 600px) {
  .admin-bar #navbar {
    top: 46px;
  }
}
@media (min-width: 782px) {
  .admin-bar #navbar {
    top: 32px;
  }
}
