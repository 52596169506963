.page-header {
  height: auto;
  padding: 2rem 1rem;
  box-shadow:
    inset 0 0 8px 2px rgba(0, 0, 0, 0.6),
    inset 0 0 0 100vh rgba(0, 0, 0, 0.4)
  ;
  background-image: url("/img/leather-02@768.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: auto;
  background-repeat: repeat;

  @media (--viewport-md-up) {
    & {
      padding: 3rem 1rem;
      background-image: url("/img/leather-02@1200.jpg");
    }
  }
  @media (--viewport-xl-up) {
    & {
      background-image: url("/img/leather-02@1440.jpg");
    }
  }

  & h1 {
    color: #fff;
    font-size: 56px;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 0px;
    text-align: center;
    @media (--viewport-xl-up) {
      & {
        font-size: 112px;
        line-height: 1;
        font-weight: 200;
        letter-spacing: -1.5px;
      }
    }
  }
}

