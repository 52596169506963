/* ========================================
On large screens, we shift the type scale «down» by one levels
On small screens, we shift the type scale «down» by two levels
This is the type scale:
headline-1 {
  font-family: Jost, sans-serif;
  font-size: 112px;
  line-height: 1;
  font-weight: 200;
  letter-spacing: -1.5px;
}
headline-2 {
  font-family: Jost, sans-serif;
  font-size: 70px;
  line-height: 1;
  font-weight: 200;
  letter-spacing: -0.5px;
}
headline-3 {
  font-family: Jost, sans-serif;
  font-size: 56px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 0px;
}
headline-4 {
  font-family: Jost, sans-serif;
  font-size: 40px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 0.25px;
}
headline-5 {
  font-family: Jost, sans-serif;
  font-size: 28px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0px;
}
headline-6 {
  font-family: Jost, sans-serif;
  font-size: 23px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.15px;
}
subtitle-1 {
  font-family: Jost, sans-serif;
  font-size: 19px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.15px;
}
subtitle-2 {
  font-family: Jost, sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1px;
}

body-1 {
  font-family: Lato, sans-serif;
  font-size: 17px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.5px;
}
body-2 {
  font-family: Lato, sans-serif;
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.25px;
}
button {
  font-family: Lato, sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1.25px;
}
caption {
  font-family: Lato, sans-serif;
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.4px;
}
overline {
  font-family: Lato, sans-serif;
  font-size: 10px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 1.5px;
}
======================================== */

body {
  background-color: var(--body-background);
}

.headline-1,
.headline-2,
.headline-3,
.headline-4,
.headline-5,
.headline-6,
.subtitle-1,
.subtitle-2,
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: Jost, sans-serif;
}

.headline-1 {
  font-size: 70px;
  line-height: 1;
  font-weight: 200;
  letter-spacing: -0.5px;
}
.headline-2, h1, .h1 {
  font-size: 56px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 0px;
}
.headline-3, h2, .h2 {
  font-size: 40px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 0.25px;
}
.headline-4, h3, .h3 {
  font-size: 28px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0px;
}
.headline-5, h4, .h4 {
  font-size: 23px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.headline-6, h5, .h5 {
  font-family: Jost, sans-serif;
  font-size: 19px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.15px;
}
.subtitle-1, h6, .h6 {
  font-family: Jost, sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.subtitle-2 {}

@media (--viewport-xl-up) {
  .headline-1 {
    font-size: 112px;
    line-height: 1;
    font-weight: 200;
    letter-spacing: -1.5px;
  }
  .headline-2, h1, .h1 {
    font-size: 70px;
    line-height: 1;
    font-weight: 200;
    letter-spacing: -0.5px;
  }
  .headline-3, h2, .h2 {
    font-size: 56px;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 0px;
  }
  .headline-4, h3, .h3 {
    font-size: 40px;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 0.25px;
  }
  .headline-5, h4, .h4 {
    font-size: 28px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0px;
  }
  .headline-6, h5, .h5 {
    font-size: 23px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 0.15px;
  }
  .subtitle-1, h6, .h6 {
    font-family: Jost, sans-serif;
    font-size: 19px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.15px;
  }
  .subtitle-2 {
    font-family: Jost, sans-serif;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 0.1px;
  }
}

// Body copy

.entry-content {
  font-size: 15px;
  line-height: 1.5rem;
  & :--heading {
    margin: 48px 0 24px 0;
  }
  @media (--viewport-xl-up) {
    & {
      font-size: 17px;
      line-height: 1.75rem;
    }
  }
}

