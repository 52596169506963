
.select2-container {
  & .select2-selection {
    //padding-block: .375rem;
    border-radius: 0;
    border: 1px solid var(--input-border-color);
    &:focus:not(:focus-visible) {
      outline: none;
    }
  }
  & .select2-dropdown {
    border-radius: 0;
  }
  & .select2-results__option {
    &[data-selected=true] {
      background-color: var(--color-primary);
      color: var(--text-on-primary);
    }
    &--highlighted {
      &[data-selected=false] {
        background-color: var(--color-secondary);
        color: var(--text-on-secondary);
      }
    }
  }
}
