
.o-shopHeader {
  margin-bottom: 3rem;

  @media (--viewport-sm-up) {
  }
  @media (--viewport-md-up) {
  }
  @media (--viewport-xl-up) {
  }
}

.o-shopControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  & > * {
    flex: 1 1 auto !important;
    @media (--viewport-lg-up) {
      &:not(.o-shopControls_layout) {
        flex: 0 0 auto !important;
      }
    }
  }
}

.o-shopControls_filter {
  @media (--viewport-xl-up) {
    display: none !important;
  }
}

.o-shopControls_sort {

}

.o-shopOrderingDialog_actions {
  display: flex;
  justify-content: flex-end;
}

.o-shopControls_layout {
  display: none;
  @media (--viewport-md-up) {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      transform: translateY(-50%);
      background-color: var(--btn-disabled-color);
    }
    .a-btn {
      --btn-color: var(--text-muted);
      --btn-disabled-color: var(--text-light);
      &, &:hover, &:focus {
        background-color: var(--body-background);
      }
    }
  }
}


