@use "../atoms/buttons";

.woocommerce .button {
  @include buttons.button-base;
  &.checkout-button {
    @include buttons.button-primary;
  }
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  & > label, & legend {
    display: block;
    margin-bottom: .5rem;
    & .required {
      color: var(--color-danger);
      font-weight: bolder;
    }
  }
  & input, & select, & textarea {
    width: 100%;
  }
}

.woocommerce-invalid {
  & input, & select, & textarea {
    border-color: var(--color-danger);
  }
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message,
.woocommerce-notice {
  list-style: none;
  padding: 1rem 1.5rem;
  margin: 0 0 1rem;
  & a {
    color: inherit;
    text-decoration: underline;
  }

  & .button {
    --btn-color: var(--text-on-info);
    --btn-text-color: var(--text-color);
    @include buttons.button-outlined;
    margin: 0 1ch;
  }
}
.woocommerce-message, .woocommerce-notice--success {
  background-color: var(--color-success, #28a745);
  color: var(--text-on-success, #fff);
}
.woocommerce-error, .woocommerce-notice--error {
  background-color: var(--color-danger);
  color: var(--text-on-danger);
}
.woocommerce-info, .woocommerce-message--info {
  background-color: var(--color-info, #2c91c3);
  color: var(--text-on-info, #fff);
}

.comment-form-rating {
  legend {
    font-size: inherit;
    margin-bottom: .5rem;
  }
}
