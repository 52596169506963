.product-variants {
  margin-bottom: 24px;
}
.product-variants-list {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

@media (--viewport-md-up) {
  .product-variants-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.product-variants-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-variants-item__thumbnail {
  border: 1px solid hsl(0, 0%, 95%);
}
.product-variants-item__thumbnail img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.product-variants-item__infos {
  margin-top: 24px;
  text-align: center;
}
.product-variants-item__name {
  font-size: 1rem;
}

.product-variants-carousel .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
