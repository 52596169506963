
a:any-link {
  color: var(--link-color);
  text-decoration: none;
  &:hover, &:focus, &:active {
    color: var(--link-hover-color);
  //text-decoration: underline;
  }
  .entry-content & {
    //text-decoration: underline;
  }
  nav & {
    text-decoration: none;
  }
}
