@use '../../utilities/pointer-events';

.o-wcLoop {
  display: grid;
  gap: 2rem 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.o-wcLoopProduct {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // font-size reset for shortcodes
  font-size: 14px;
  line-height: 24px;
}

.o-wcLoopProduct_thumbnail img {
  width: 100%;
  height: auto;
  object-fit: contain;
  transform: scale(1.0);
  transition: transform 600ms var(--deceleration-curve-timing-function);
}
.o-wcLoopProduct:hover .o-wcLoopProduct_thumbnail img {
  transform: scale(1.1);
}

.o-wcLoopProduct_badges {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > * {
    margin-top: 1rem;
  }
}

.o-wcLoopProduct_stock {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.o-wcLoopProduct_stockStatus {
  font-size: 13px;
  line-height: 1.5rem;
  text-align: center;
  margin: 1rem;
  text-transform: uppercase;
}
.o-wcLoopProduct_stockStatus.-outOfStock {
  background-color: var(--color-danger);
  color: var(--text-on-danger);
}

.o-wcLoopProduct_actions {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 15%;
  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms var(--standard-curve-timing-function);

  & > * {
    transform: translateY(20%);
    transition: transform .3s var(--standard-curve-timing-function);
  }

  .a-btn {
    --btn-color: white;
    --btn-text-color: black;
  }

  &:focus-within,
  .o-wcLoopProduct:hover &
  {
    opacity: 1;
    pointer-events: auto;
    @include pointer-events.delay(300ms);

    & > * {
      transform: translateY(0px);
    }
  }
}

// Quick view button
.o-wcLoopProduct_quickView {
  --btn-hover-color: white;
  .a-icn {
    --text-color: white;
  }
}

// Quick view button - loading state
.o-wcLoopProduct_quickView.-loading {
  padding: 0;
  .a-icn {
    display: none;
  }
  &:after {
    content: "";
    display: block;
    margin: 4px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: #fff transparent;
    animation: quick-view-loading 1.2s linear infinite;
  }
}

@keyframes quick-view-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.o-wcLoopProduct_infos {
  text-align: center;
}
.o-wcLoopProduct_name {
  margin: 0 0 .25rem 0 !important;
  font-size: 23px;
  line-height: 1;
  letter-spacing: 0.15px;
}
.o-wcLoopProduct_name a {
  color: var(--text-color);
}

.o-wcLoopProduct_attributes {
  line-height: 1.5;
}
.o-wcLoopProduct_price {
  font-weight: bold;
}

@media (--viewport-md-up) {
  .o-wcLoop[data-columns="2"] {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  .o-wcLoop[data-columns="3"] {
    grid-template-columns: repeat(3, 1fr);
  }
  .o-wcLoop[data-columns="4"] {
    grid-template-columns: repeat(4, 1fr);
  }
  .o-wcLoop[data-layout-type="list"] {
    grid-template-columns: auto;
  }
  .o-wcLoop[data-layout-type="list"] .o-wcLoopProduct {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    padding-bottom: 2rem;
  }
  .o-wcLoop[data-layout-type="list"] .o-wcLoopProduct_infos {
    text-align: left;
  }
}
