
@keyframes disable-pointer-events {
  0%, 99% {
    pointer-events: none;
  }
}

@mixin delay($duration: 300ms) {
  animation: disable-pointer-events $duration;
}
