@mixin unstyled-list() {
  list-style: none;
  padding: 0;
  margin: 0;
}

.a-unstyledList,
nav ul,
nav ol {
  @include unstyled-list();
}

@mixin key-value-grid($bordered: true, $striped: true) {
  display: grid;
  grid-template-columns: max-content 1fr;
  margin-bottom: 1.5rem;
  dt, dd {
    margin: 0;
    padding: .5rem;
  }
  dt {
    font-weight: bold;
  }
  dd {
    font-style: italic;
  }
  @if ($bordered) {
    dt, dd {
      border: 1px solid var(--grey-200);
      &:not(:first-of-type) {
        border-top: none;
      }
    }
    dd {
      border-left: none;
    }
  }
  @if ($striped) {
    dt, dd {
      &:nth-of-type(even) {
        background-color: var(--grey-100);
      }
    }
  }
}

@mixin key-value-list() {
  overflow: hidden;
  dt, dd {
    display: inline-block;
    float: left;
    margin: 0;
  }
  dt {
    clear: left;
    font-weight: bold;
    &::after {
      content: '\A0:\A0';
    }
  }
  dd + dd::before {
    content: ', ';
  }
}

dl.a-keyValueGrid {
  @include key-value-grid();
}

dl.a-keyValueList {
  @include key-value-list();
}
