.m-details {
  > summary {
    display: inline-flex;
    align-items: center;
    &::marker, &::-webkit-details-marker {
      display: none;
    }
    &::before {
      content: "";
      display: inline-flex;
      width: 1em;
      height: 1em;
      background-color: currentColor;
      clip-path: url(#clip-expand-down);
      transform: rotate(-90deg);
      transition: transform 150ms var(--standard-curve-timing-function);
    }
  }
  &[open] {
    > summary::before {
      transform: rotate(0deg);
    }
  }
  &_content {
    margin: 1rem 0;
  }
}
