@use "../atoms/lists";

.o-checkoutForm {
  .woocommerce-NoticeGroup {
    margin-top: 1.5rem;
  }
  &_content.-hasFields {
    @media (--viewport-md-up) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 3rem;
      align-items: baseline;
    }
  }
  #ship-to-different-address {
    font-family: inherit;
    font-size: inherit;
  }

  .woocommerce-billing-fields__field-wrapper,
  .woocommerce-shipping-fields__field-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    & > * {
      grid-column: auto / span 2;
    }
  }
  @media (--viewport-xl-up) {
    #billing_first_name_field,
    #billing_postcode_field,
    #billing_phone_field,
    #shipping_first_name_field,
    #shipping_postcode_field,
    #shipping_phone_field,
    {
      grid-column: 1 / span 1;
    }
    #billing_last_name_field,
    #billing_city_field,
    #billing_email_field,
    #shipping_last_name_field,
    #shipping_city_field,
    #shipping_email_field,
    {
      grid-column: 2 / span 1;
    }
  }
}

.woocommerce-checkout-review-order-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
  th {
    font-weight: bold;
  }
  tbody, tfoot {
    tr {
      border-top: 1px solid var(--grey-300);
    }
  }
  th, td {
    vertical-align: top;
    padding: .5rem;
  }
  .order-total {
    font-size: 23px;
  }
}

.woocommerce-order-overview {
  @include lists.unstyled-list();
}

.wc_payment_methods {
  @include lists.unstyled-list();
  margin-bottom: 1.5rem;
  .payment_box {
    margin-block: .5rem;
    padding: 1rem;
    background-color: var(--color-info);
    color: var(--text-on-info);
    :last-child {
      margin-bottom: 0;
    }
  }
}
