@use '../../utilities/visually-hidden';

.active-filters {
  margin-bottom: 1.5rem;
}

.active-filters-list,
.product-filter-options-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.active-filters-list-item,
.product-filter-options-list-item {

}

.active-filters-list {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1ch;
}
.active-filters-link {
  color: inherit;
  display: inline-flex;
  align-items: center;
  gap: .5ch;
}
.active-filters-link::before {
  content: "";
  width: 1.125rem;
  height: 1.125rem;
  background-color: currentColor;
  clip-path: url(#clip-highlight-off);
}

.product-filter + .product-filter {
  margin-top: 1.5rem;
}
.product-filter-control {
  margin: 0;
  line-height: 1;
}

.product-filter-header--hidden {
  @include visually-hidden.visually-hidden();
}

.product-filter-header button {
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: inline-grid;
  grid-template-columns: 1fr min-content;
  gap: 1ch;
  align-items: center;
  &:hover, &:focus {
    color: var(--btn-hover-color, inherit);
    background-color: transparent;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
}
.product-filter--collapsible .product-filter-header button::after {
  content: "";
  width: 1em;
  height: 1em;
  background-color: currentColor;
  clip-path: url(#clip-expand-down);
  transform: rotate(-180deg);
  transition: transform 150ms var(--standard-curve-timing-function);
}
.product-filter--collapsed .product-filter-header button::after {
  transform: rotate(0deg);
}

.product-filter .search-field {
  width: 100%;
}

#shop-filter-dialog {
  .o-modal_container {
    @media (--viewport-lg-up) {
      max-width: min(80vw, 800px);
      max-height: 90vh;
    }
  }
}

.o-shopFilterDialog_actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.o-shopSidebar_actions {
  position: sticky;
  bottom: 0;
  z-index: calc(var(--z-index-appbar) - 1);
  display: flex;
  justify-content: space-between;
  gap: 1ch;
  padding: .5rem 0;
  background-color: var(--body-background, #fff);
  transition: box-shadow 250ms ease-in;

  &.-hasElevation {
    box-shadow: var(--elevation-bottom-bar);
  }

  & > *:only-child {
    justify-self: flex-end;
    margin-left: auto;
  }
}
