.o-siteFooter {
  color: var(--footer-text-color);
  background: var(--footer-background);
  box-shadow:
    inset 0 2px 4px -1px rgba(0,0,0,.8),
    inset 0 4px 5px 0 rgba(0,0,0,.74),
    inset 0 1px 10px 0 rgba(0,0,0,.72)
  ;

  :--heading {
    color: var(--footer-text-color);
  }
  :any-link {
    color: var(--footer-link-color);
    transition: color .3s ease-in-out;
    &:hover, &:focus {
      color: var(--footer-text-color);
    }
  }

  & a .fa, & a .fas {
    color: var(--footer-text-color);
  }
}

.o-siteFooter_row {
  padding: 2rem 0;
  &:not(:first-child) {
    border-top: 1px solid hsl(16, 10%, 32%);
  }
}

.o-siteFooter_siteInfos {
  display: grid;
  gap: 0.5rem;
  background-color: transparent;
  @media (--viewport-md-up) {
    & {
      grid-template-columns: repeat(2, auto);
      justify-content: space-between;
    }
  }
}

.o-siteFooter_menus {
  display: grid;
  gap: 1ch;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.o-siteFooter_menu .menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.o-siteFooter_contacts {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  @media (--viewport-md-up) {
    & {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.o-siteFooter_logo {
  --logo-color: #fff;
  --logo-thread-color: var(--color-primary);
  display: block;
  width: 100%;
  height: auto;
  @media (--viewport-md-up) {
    & {
      display: block;
      height: 100%;
    }
  }
}
