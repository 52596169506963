.logo-mgants {
  font-family: Jost, sans-serif;
  font-size: 20px;
  font-weight: 200;
  letter-spacing: -0.5px;
}

.m-siteLogo {
  display: grid;
  gap: .5rem;
  font-family: Jost, sans-serif;
  font-weight: 200;
  letter-spacing: -0.5px;
  padding: 1rem;
  text-align: center;
  &:link, &:visited, &:hover, &:focus {
    color: var(--color-black);
  }
}
.m-siteLogo.-isSticky {
  font-size: 40px;
  line-height: 1;
  letter-spacing: 0.25px;
}

.m-siteLogo_image {
  width: 100%;
  height: auto;
  max-height: 160px;
}
.m-siteLogo_subtitle {
  display: none;
  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
  @media (--viewport-lg-up) {
    & {
      display: block;
    }
  }
}

