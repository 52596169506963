
.color-swatch-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
  grid-auto-rows: 30px;
}

.color-swatch-list-item {
  margin: 0 !important;
}

.color-swatch-control {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0;
  display: grid;
}

.color-swatch-control__input {
  grid-area: 1 / 1;
  appearance: none;
}

.color-swatch-control__label {
  grid-area: 1 / 1;
  display: inline-grid;
  cursor: pointer;
  background-color: var(--swatch-color, white);
  color: var(--contrast-color, black);
}

.color-swatch-control__label::after {
  content: "";
  grid-area: 1 / 1;
  background-color: currentColor;
  clip-path: var(--shape-checkbox-mark);
  opacity: 0;
  transition: opacity 150ms;
}
// https://github.com/jonathantneal/postcss-nesting/issues/67
.color-swatch-control__input:checked + .color-swatch-control__label::after {
  opacity: 1;
}
.color-swatch-control__input:disabled + .color-swatch-control__label {
  cursor: not-allowed;
  opacity: 0.1;
}
