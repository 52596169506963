.o-siteNav {
  display: none;
}
@media (--viewport-lg-up) {
  .o-siteNav {
    display: block;
    background-color: var(--site-nav-bg-color);
    @media (--viewport-lg-up) {
      & {
        display: block;
      }
    }
  }
  .o-siteNav_container {
    display: flex;
    justify-content: space-between;
  }

  .o-siteNav_menuItem, .o-siteNav_subMenu {
    list-style: none;
    padding: 0;
    line-height: unset;
  }

  .o-siteNav_mainMenu {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .o-siteNav_menuItem.-isTopLevel {
    & > a {
      display: inline-flex;
      align-items: center;
      gap: .5ch;
      padding: 1rem 0;
      color: var(--site-nav-link-color);
      text-transform: uppercase;
      &:hover, &:focus {
        outline: none;
        color: var(--site-nav-link-color-hover);
      }
    }

    &.-hasChildren {
      & > a::after {
        content: "";
        width: 18px;
        height: 18px;
        background-color: currentColor;
        clip-path: url(#clip-expand-down);
        transform: rotate(0deg);
        transition: transform 150ms var(--standard-curve-timing-function);
      }
      &.-isOpen > a::after {
        transform: rotate(-180deg);
      }
    }
    &:not(.-hasMegaMenu) {
      position: relative;
    }
    & > .o-siteNav_subMenu,
    & > .o-siteNav_megaMenu {
      position: absolute;
      top: 100%;
      background-color: white;
      color: #969696;
      box-shadow: var(--elevation-z8);
      opacity: 0;
      transform: translateY(1rem);
      pointer-events: none;
      transition: all .3s ease-out;
    }
    & > .o-siteNav_subMenu {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      padding: 1rem;
      width: max-content;
      max-width: 50vw;
      right: -1rem; // for alignment w/ link padding
    }
    &.-isOpen {
      & > .o-siteNav_subMenu,
      & > .o-siteNav_megaMenu {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
      }
    }
  }

  .o-siteNav_subMenu a {
    color: var(--site-nav-submenu-link-color);
    &:hover, &:focus {
      color: var(--site-nav-submenu-link-color-hover);
    }
  }

  .o-siteNav_megaMenu {
    overflow-y: auto;
    left: 0;
    right: 0;
    max-width: none;
    padding: 1.5rem;
    max-height: 50vh;
  }

  .o-siteNav_subMenu.-isMegaMenu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    .o-siteNav_menuItem.-isFullWidth {
      grid-column: 1 / -1;
    }
    .o-siteNav_subMenu {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1ch;
    }
  }

  .o-siteNav_megaMenu_title {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  .o-siteNav_secondary {
    display: flex;
    align-items: center;

    & .a-btn {
      --btn-color: var(--text-on-dark-background);
      --btn-hover-color: var(--text-on-dark-background);
    }

    & .fa {
      font-size: 18px;
    }

    & .woo-tools-action {
      display: table-cell;
      width: 38px;
      height: 45px;
      padding: 0;
      line-height: 45px;
      text-align: center;
    }

    & .cart-link {
      color: var(--text-on-dark-background);
    }

    & .cart-count {
      background: var(--color-primary) !important;
    }
  }
}

