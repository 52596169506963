
.o-tabList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.o-tabList_tab {
  flex-grow: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1ch;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  padding: 1rem 2rem;
  background-color: var(--grey-100);
  position: relative;
  transition: background-color 250ms var(--standard-curve-timing-function);

  &:hover, &:focus:not(:focus-visible) {
    outline: none;
    background-color: var(--grey-200);
  }
  &.-isActive {
    color: var(--color-primary);
    z-index: 1;
  }
}

.o-tabList_tabIndicator {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--color-primary);
  transform-origin: left;
  opacity: 0;
  .o-tabList_tab.-isActive & {
    opacity: 1;
  }
}

.o-tabs_panels {
  margin-top: 1.5rem;
}
.o-tabs_panel:not(.-isActive) {
  display: none;
}
