
// Specificity war...
.wpcf7 .wpcf7-form {
  .wpcf7-form-control-wrap {
    display: block;
  }

  .wpcf7-not-valid-tip {
    color: var(--color-danger);
    font-style: italic;
  }

  .wpcf7-response-output {
    border: none;
    color: var(--text-on-info);
    background-color: var(--color-info);
    margin: 3rem 0 1.5rem;
    padding: 1rem;
  }

  &.sent {
    .wpcf7-response-output {
      color: var(--text-on-success);
      background-color: var(--color-success);
    }
  }
  &.failed, &.aborted, &.spam {
    .wpcf7-response-output {
      color: var(--text-on-danger);
      background-color: var(--color-danger);
    }
  }
  &.invalid, &.unaccepted, &.payment-required {
    .wpcf7-response-output {
      color: var(--text-on-warning);
      background-color: var(--color-warning);
    }
  }

}
