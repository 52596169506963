.woo-tools-action.woo-tools-cart .widget_shopping_cart {
  width: auto;
  min-width: 350px;
}
.woo-tools-action.woo-tools-cart .woocommerce-mini-cart__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.woo-tools-action.woo-tools-cart .woocommerce-mini-cart__buttons .button {
  width: auto;
}
