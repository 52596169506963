/**
 * Login / Register page
 */
.woocommerce-account {
  & .u-columns {
    display: grid;
    grid-template-columns: auto;
    gap: 3rem;
    @media (--viewport-md-up) {
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }
    @media (--viewport-xl-up) {
      gap: 3rem;
    }
  }
}

.woocommerce-account.logged-in {
  & .woocommerce {
    display: grid;
    grid-template-columns: auto;
    gap: 3rem;
    @media (--viewport-md-up) {
      grid-template-columns: 1fr 2fr;
      gap: 1.5rem;
    }
    @media (--viewport-lg-up) {
      grid-template-columns: 1fr 3fr;
      gap: 3rem;
    }
  }
}

.woocommerce-MyAccount-navigation {
  & > ul {
    border: 1px solid var(--grey-300);
  }
}
.woocommerce-MyAccount-navigation-link {
  & a {
    display: flex;
    gap: 1ch;
    padding: .5rem;
    color: inherit;
  }
  &:not(:first-child) {
    border-top: 1px solid var(--grey-300);
  }
  &.is-active {
    background-color: var(--grey-100);
  }
}

.woocommerce-EditAccountForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  & .clear {
    display: none;
  }
  & > * {
    grid-column: 1 / span 2;
  }
  @media (--viewport-md-up) {
    & > .form-row-first,
    & > .form-row-last {
      grid-column: auto;
    }
  }

  & legend {
    margin: 2rem 0 1rem;
  }
}

.woocommerce-edit-address {
  & .woocommerce-address-fields__field-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    & > * {
      grid-column: 1 / span 2;
    }
    @media (--viewport-md-up) {
      & > .form-row-first,
      & > .form-row-last {
        grid-column: auto;
      }
    }
  }
}

.woocommerce-ResetPassword {
  // lost-password page submit button
  & .woocommerce-Button[type="submit"] {
    align-self: flex-start;
  }
}

.woocommerce-MyAccount-orders {
  width: 100%;
  th {
    font-weight: bold;
  }
  th, td {
    padding: .5rem;
  }
  tbody, tfoot {
    tr {
      border-top: 1px solid var(--grey-300);
    }
  }
}

.woocommerce-table--order-details {
  th, td {
    padding: .5rem;
  }
  th {
    font-weight: bold;
  }
  tbody, tfoot {
    tr {
      border-top: 1px solid var(--grey-300);
    }
  }
}
