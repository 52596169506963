:root {
  --modal-open-transition-duration: 150ms;
  --modal-close-transition-duration: 75ms;
}

body.-hasModal {
  overflow: hidden;
}

.o-modalStack_scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-dialog);
  background-color: rgba(0, 0, 0, 0.32);
  body.-hasModal & {
    display: block;
  }
}

.o-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-dialog);
  pointer-events: none;
  &.-isOpened {
    display: flex;
  }
}
.o-modal_container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: var(--body-background);
  box-shadow: var(--elevation-dialog);
  pointer-events: auto;
  max-width: 95vw;
  max-height: 95vh;
  @media (--viewport-md-up) {
    max-width: min(80vw, 1300px);
    max-height: 90vh;
  }
}
.o-modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2ch;
  padding: .5rem 1rem;
}
.o-modal_title {
  font-family: Jost, sans-serif;
  font-size: 23px;
  font-weight: 300;
  letter-spacing: 0.15px;
}
.o-modal_close {
  order: 1;
  &.a-btn {
    margin-left: -1rem;
  }
}
.o-modal_main {
  overflow-y: auto;
}

.o-modal_content {
  padding: 1rem;
}

.o-modal_footer:not(:empty) {
  padding: .5rem 1rem;
}

.o-modal_header, .o-modal_footer {
  transition: box-shadow 250ms ease-in;
  box-shadow: var(--elevation-z0);
  z-index: var(--z-index-appbar);
}
.o-modal_container {
   &.-hasElevationTop .o-modal_header {
    box-shadow: var(--elevation-z4);
  }
  &.-hasElevationBottom .o-modal_footer {
    box-shadow: var(--elevation-z4);
  }
}

.o-modal.-fullscreen {
  .o-modal_container {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    @media (--viewport-lg-up) {
      max-width: min(80vw, 1300px);
      max-height: 90vh;
    }
  }
}

