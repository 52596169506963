.o-wcSingleProduct {
  display: grid;
  grid-template-columns: 100%;
  gap: 1rem;
}

@media (--viewport-lg-up) {
  .o-wcSingleProduct {
    grid-template-areas:
      "gallery summary"
      "gallery cart"
      "gallery meta"
      "rest rest"
    ;
    grid-template-columns: minmax(0, 1fr) 1fr;
    grid-template-rows: auto auto 1fr auto;
    gap: 1.5rem;
  }
  .o-wcSingleProduct_gallery {
    grid-area: gallery;
  }
  .o-wcSingleProduct_summary {
    grid-area: summary;
  }
  .o-wcSingleProduct_addToCart {
    grid-area: cart;
  }
  .o-wcSingleProduct_meta {
    grid-area: meta;
  }
  .o-wcSingleProduct_bottom {
    grid-area: rest;
    // Needed for swiper to compute slide dimensions correctly under firefox
    // see: https://github.com/nolimits4web/swiper/issues/2218
    width: 100%;
  }
}

@media (--viewport-xl-up) {
  .o-wcSingleProduct {
    grid-template-columns: 600px 1fr;
  }
}

.o-wcSingleProduct_summary,
.woocommerce-variation-price {
  .price {
    font-size: 23px;
    color: var(--color-primary);
    del {
      color: var(--grey-400);
    }
    ins {
      text-decoration: none;
    }
  }
}

.reset-variations-container {
  margin-top: 1rem;
}

.woocommerce-variation-availability {
  .stock {
    &.out-of-stock {
      padding: 1rem;
      background-color: var(--color-danger);
      color: var(--text-on-danger);
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
    }
    &.in-stock {
      display: inline-block;
      padding: .5em 2ch;
      background-color: var(--color-success);
      color: var(--text-on-success);
    }
  }
}

.woocommerce-variation-add-to-cart {
  display: flex;
  gap: 2ch;
}

.o-wcSingleProduct_attributeValue {
  p {
    margin: 0;
  }
}

.o-wcSingleProduct_related {
  margin-top: 3rem;
}

.o-wcSingleProduct_paymentInfos {
  margin-top: 1rem;
}
