#content {
  padding: 2rem 1rem;
  @media (--viewport-lg-up) {
    padding: 4rem 1rem;
  }
  &.shop-content {
    @media (--viewport-xl-up) {
      display: grid;
      grid-template: "sidebar main" / 1fr 3fr;
      gap: 2rem;
    }
  }
}

#shop-sidebar {
  display: none;
  @media (--viewport-xl-up) {
    display: block;
    grid-area: sidebar;
  }
}
