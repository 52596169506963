.woocommerce-Reviews {
  .commentlist {
    list-style: none;
    padding: 0;
  }
  .review {
    margin-bottom: 1.5rem;
  }
  .comment_container {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1.5rem;
  }
  .comment-text {
    border: 1px solid var(--grey-200);
    padding: 1rem;
  }
  .meta {
    margin: 0;
  }
  .woocommerce-review__author {
    font-weight: bold;
  }
  .description :last-child {
    margin-bottom: 0;
  }
}
