
.woocommerce-pagination {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  font-size: 18px;
  & > * {
    border: 1px solid var(--grey-300);
    background-color: var(--grey-100);
    padding: .375rem 1.25rem;
  }
  & > * + * {
    border-left: none;
  }
  .current {
    background-color: var(--color-primary-dark);
    border-color: var(--color-primary-dark);
    color: var(--text-on-primary);
  }
  .prev, .next {
    display: inline-flex;
    padding: .375rem .75rem;
  }
}
