.search-results {
  .post {
    & + .post {
      margin-top: 1.5rem;
    }
    &.-hasThumbnail {
      display: grid;
      @media (--viewport-sm-up) {
        grid-template-columns: auto 1fr;
        gap: 1rem;
      }
    }
  }
}
