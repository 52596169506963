.price_slider {
  --handle-size: 24px;
  --track-size: 6px;
  @media (pointer: fine) {
    --handle-size: 18px;
    --track-size: 4px;
  }
  --half-handle-size: calc(var(--handle-size) / 2);
  --half-track-size: calc(var(--track-size) / 2);

  position: relative;
  margin: 0 var(--half-handle-size) var(--handle-size) var(--half-handle-size);
  height: var(--track-size);
  background: var(--grey-400);
  & .ui-slider-range {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
  }
  & .ui-slider-handle {
    position: absolute;
    z-index: 2;
    top: calc((var(--half-handle-size) - var(--half-track-size)) * -1);
    width: var(--handle-size);
    height: var(--handle-size);
    margin-left: calc(var(--half-handle-size) * -1);
    background-color: var(--body-background);
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    cursor: ew-resize;
    &:focus:not(:focus-visible) {
      outline: none;
    }
    &:first-of-type {
      left: 0;
    }
    &:last-of-type {
      left: 100%;
    }
  }
}

.price_slider_amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1ch;
}
