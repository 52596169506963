
.m-layerStack {
  display: grid;
  overflow: hidden;
  grid-template-areas: "layers";
}
.m-layerStack > * {
  grid-area: layers;
  position: relative;
}
