.variations_form .variations {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;
}
.variations_form .variation-attribute {
  display: contents;
}
.variations_form .variation-attribute .label {
  font-weight: bold;
}
.variations_form .variation-attribute--has-color-swatch .label {
  align-self: baseline;
}

.variations_form .single_variation_wrap {
  margin-top: 1rem;
}
