.o-appBar {
  padding: 1rem 0;
  background-color: var(--body-background);
  @media (--viewport-lg-up) {
    & {
      display: none;
    }
  }
}
.o-appBar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2ch;
}

.o-appBar_title {
  margin: 0;
  font-size: 28px;
  font-weight: 300;
  text-transform: uppercase;
  @media (--viewport-sm-up) {
    & {
      font-size: 40px;
      font-weight: 200;
    }
  }
}
.o-appBar_toolBar {
  display: flex;
  align-items: center;
  @media (--viewport-sm-up) {
    gap: 1ch;
  }
}
