.o-cookieConsent {
  position: fixed;
  z-index: calc(var(--z-index-appbar) + 1);
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--body-background);

  box-shadow: var(--elevation-bottom-appbar);

  &_container {
    padding: 1rem;
    display: grid;
    gap: 1rem;
    @media (--viewport-lg-up) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &_message {
    font-size: 15px;
    @media (--viewport-xl-up) {
      font-size: 17px;
    }
  }

  &_actions {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    @media (--viewport-sm-up) {
      flex-direction: row;
      gap: 1rem;
    }
    & > * {
      flex-grow: 1;
    }
  }
}
