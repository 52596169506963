.o-siteTopBar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
}

.o-siteTopBar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.o-siteTopBar_i18nMenu {
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 2ch;
  }
}

.o-siteTopBar_languageSwitcher {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.o-siteTopBar_languageSwitcher_language:not(:first-child) {
  margin-left: 1ch;
}

.o-siteTopBar_currencySwitcher .wcml_currency_switcher {
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.o-siteTopBar_userMenu {
  display: flex;
  align-items: center;
  & > * {
    margin-left: 2ch;
  }
  & .fas {
    color: var(--text-color);
  }
}
