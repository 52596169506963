.o-productQuickView {
  display: grid;
  grid-template-columns: 100%;
  gap: 1rem;
  @media (--viewport-md-up) {
    grid-template:
      "gallery summary" auto
      "gallery cart" 1fr
      "meta meta" auto
      / 1fr 1fr
    ;

    .o-productQuickView_summary {
      grid-area: summary;
    }
    .o-productQuickView_gallery {
      grid-area: gallery;
    }
    .o-productQuickView_addToCart {
      grid-area: cart;
    }
    .o-productQuickView_meta {
      grid-area: meta;
    }
    .o-productQuickView_description {
      display: block;
    }
  }
  @media (--viewport-xl-up) {
    grid-template:
      "gallery summary" auto
      "gallery cart" auto
      "gallery meta" 1fr
      / 1fr 1fr
    ;
    gap: 1.5rem;
  }
}

.o-productQuickView_gallery {
  overflow: hidden;
}

.o-productQuickView_description {
  display: none;
}

.o-productQuickView_price {
  font-size: 1.5rem;
}

.o-productQuickView_permalink .a-btn {
  width: 100%;
}

//.o-productQuickView_gallery__image img {
//  max-width: 100%;
//  height: auto;
//  max-height: 600px;
//  object-fit: contain;
//}
