.o-mobileNav {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.o-mobileNav_menu, .o-mobileNav_subMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: var(--body-background);
}

.o-mobileNav_subMenu {
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 250ms var(--standard-curve-timing-function);
  &.-isOpen {
    transform: translateX(0%);
  }
}

.o-mobileNav_menuItem {
  padding: .5rem 0;
  border-top: 1px solid #ddd;

  &:last-child {
    border-bottom: 1px solid #ddd;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-color);
    text-transform: uppercase;
  }
}
