.m-starRating {
  &_stars {
    color: var(--color-primary);
  }
}

.m-starRatingInput {
  display: flex;
  flex-flow: row-reverse;
  justify-content: flex-end;
  gap: 1ch;
  input {
    all: unset;
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  label {
    display: inline-grid;
    cursor: pointer;
    .a-icn {
      grid-area: 1 / 1 / 1 / 1;
      color: var(--color-primary);
    }
    .a-icn-star {
      opacity: 0;
      transition: opacity 150ms var(--standard-curve-timing-function);
    }
  }
  input:checked ~ label .a-icn-star {
    opacity: 1;
  }
  input:focus:not(:checked) + label {
    color: var(--color-primary);
  }
}
